@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Inter Font (ShadCN default) */
@import "@fontsource/inter/index.css";  /* ✅ Works with Next.js & Vite */

/* Apply Inter as the Default Font */
:root {
  --font-sans: "Inter", sans-serif;
  --background: #f8f9fa; /* Light gray background */
  --foreground: #111827; /* Dark text */
}

body {
  /*font-family: var(--font-sans);*/
  /*background-color: var(--background);*/
  /*color: var(--foreground);*/
  @apply antialiased; /* Makes text rendering smoother */
}

/* Ensure Buttons, Inputs, and Forms Look Consistent */
button,
input,
textarea,
select {
  font-family: inherit;
}

/*!* Improve ShadCN Dialog & Modal Appearance *!*/
/*.dialog-content {*/
/*  @apply bg-white p-6 rounded-xl shadow-lg;*/
/*}*/
